<template>
  <v-card tile color="grey lighten-2" elevation="0" class="rounded-b-lg">
    <v-card-text v-if="loading">
      <v-progress-linear indeterminate color="white" />
    </v-card-text>
    <v-card-text v-if="!loading">
      <v-row class="pt-0 px-0 pb-0 ma-0">
        <v-col sm="3" class="pa-1">
          <v-autocomplete
            class="rounded-lg"
            v-model="messageTypeCode"
            :items="messageTypeItems"
            background-color="white"
            placeholder="Message Type"
            outlined
            hide-details
            dense
          />
        </v-col>
        <v-col class="pa-1">
          <v-textarea
            class="rounded-xl"
            v-model="message"
            background-color="white"
            placeholder="Message"
            auto-grow
            outlined
            dense
            hide-details
            rows="1"
          >
            <template v-slot:append>
              <v-file-input
                v-if="!confirmToSend"
                v-model="attachmentsInput"
                hide-input
                multiple
                class="chatbox-buttons"
              />
              <v-tooltip
                left
                :disabled="validation === true"
                v-if="!confirmToSend"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    class="chatbox-buttons"
                    @click="triggerSend()"
                  >
                    <v-icon>mdi-send</v-icon>
                  </v-btn>
                </template>
                <div v-if="!(validation === true)">
                  <ul>
                    <li v-for="(error, index) in validation" :key="index">
                      {{ error }}
                    </li>
                  </ul>
                </div>
              </v-tooltip>
              <v-btn
                v-if="confirmToSend"
                color="error"
                x-small
                elevation="0"
                class="rounded-lg chatbox-buttons"
                @click="send()"
              >
                Confirm
              </v-btn>
            </template>
          </v-textarea>
        </v-col>
      </v-row>
      <v-row class="pt-1 px-0 pb-0 ma-0">
        <v-col sm="3" class="pa-1" v-if="needsCaseComponent">
          <v-autocomplete
            class="rounded-lg"
            v-model="caseUID"
            :items="caseComponentsItems"
            background-color="white"
            placeholder="Case Component"
            outlined
            hide-details
            dense
          />
        </v-col>
        <v-col sm="3" class="pa-1" v-if="needsApproval">
          <v-autocomplete
            class="rounded-lg"
            v-model="approvalUID"
            :items="approvalItems"
            background-color="white"
            placeholder="Approval Request"
            outlined
            hide-details
            dense
          />
        </v-col>
        <v-col sm="3" class="pa-1" v-if="needsOutsource">
          <v-autocomplete
            class="rounded-lg"
            v-model="outsourceUID"
            :items="outsourceJobItems"
            background-color="white"
            placeholder="Outsource Job"
            outlined
            hide-details
            dense
          />
        </v-col>
        <v-col sm="3" class="pa-1" v-if="needsRecipient">
          <v-autocomplete
            class="rounded-lg"
            v-model="employeeReference"
            :items="employeeItems"
            background-color="white"
            placeholder="Recipient (leave blank for all)"
            dense
            outlined
            hide-details
            clearable
          >
            <template v-slot:item="data">
              <div class="ma-3">
                <strong>
                  {{ data.item.text }}
                </strong>
                <br />
                <small>
                  <span class="mr-2" v-if="data.item.branch">
                    <v-icon small>mdi-factory</v-icon> {{ data.item.branch }}
                  </span>
                  <span v-if="data.item.department">
                    <v-icon small>mdi-account-group</v-icon>
                    {{ data.item.department }}
                  </span>
                </small>
              </div>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col sm="3" class="pa-0">
          <span v-if="messageType" class="ml-2">
            <v-chip class="mt-1" v-if="messageType.visibility === 'STAFF'">
              <v-icon small class="mr-2">mdi-check</v-icon>
              Visible only to Osteon Staff
            </v-chip>
            <v-chip class="mt-1" v-if="messageType.visibility === 'PARTNERS'">
              <v-icon small class="mr-2">mdi-information</v-icon>
              Visible to OS Partners
            </v-chip>
            <v-chip class="mt-1" v-if="messageType.visibility === 'SALES'">
              <v-icon small class="mr-2">mdi-information</v-icon>
              Visible to Sales Team
            </v-chip>
            <v-chip
              color="error"
              class="mt-1"
              v-if="messageType.visibility === 'CLIENTS'"
            >
              <v-icon small class="mr-2">mdi-alert</v-icon>
              Visible to Clients
            </v-chip>
          </span>
        </v-col>
        <v-col class="pa-0">
          <v-chip
            color="white"
            small
            v-for="(attachment, index) in attachments"
            :key="index"
            close
            @click:close="removeAttachment(attachment)"
            class="mr-2 mt-1"
          >
            <v-icon>mdi-paperclip</v-icon>
            {{ attachment.name }}
          </v-chip>
        </v-col>
      </v-row>
      <v-row class="pt-1 px-0 pb-0 ma-0" v-if="errorMessage">
        <v-col sm="3" />
        <v-col class="pa-0">
          <v-alert
            type="error"
            dismissible
            v-if="errorMessage"
            class="text-center ma-1 py-1 px-3 rounded-xl"
          >
            {{ errorMessage }}
          </v-alert>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import fetchURLParam from "@/shared/url_params";
import options from "@/plugins/axios_options";
import { modifyHtmlContent } from "@/utils/modify_html";

export default {
  name: "Compose",
  props: ["kase"],
  data() {
    return {
      confirmToSend: false,
      loading: false,
      caseUID: null,
      approvalUID: null,
      outsourceUID: null,
      employeeReference: null,
      messageTypeCode: null,
      message: null,
      attachments: [],
      errorMessage: null,
    };
  },
  watch: {
    messageFilter(value) {
      this.matchFilters(value);
    },
  },
  computed: {
    ...mapGetters([
      "collection",
      "messageTypes",
      "messageFilter",
      "employees",
      "me",
    ]),
    selectedCase() {
      if (this.kase) {
        return this.kase;
      }
      if (this.caseUID) {
        return this.collection.cases.find((kase) => kase.uid === this.caseUID);
      }
      return null;
    },
    attachmentsInput: {
      get() {
        return this.attachments;
      },
      async set(value) {
        for (const attachment of value) {
          if (
            this.attachments.some(
              (existing) => existing.name === attachment.name
            )
          ) {
            continue;
          }

          if (attachment.type === "text/html") {
            try {
              const modifiedContent = await modifyHtmlContent(attachment);
              const newAttachment = new File(
                [modifiedContent],
                attachment.name,
                {
                  type: attachment.type,
                }
              );

              this.attachments.push(newAttachment);
            } catch (error) {
              this.errorMessage = "Failed to process the HTML file.";
            }
          } else {
            this.attachments.push(attachment);
          }
        }
      },
    },
    needsCaseComponent() {
      if (this.kase) {
        return false;
      }
      if (!this.messageTypeCode) {
        return false;
      }
      if (
        this.messageTypeCode === "CLIENT_NOTE" ||
        this.messageTypeCode === "CLIENT_MESSAGE" ||
        this.messageTypeCode === "CUSTOMER_SCAN_ADVICE" ||
        this.messageTypeCode === "SALES_MESSAGE" ||
        this.messageTypeCode === "CUSTOMER_SERVICE_NOTE" ||
        this.messageTypeCode === "APPROVAL_MESSAGE"
      ) {
        return false;
      }
      return true;
    },
    needsOutsource() {
      if (!this.selectedCase) {
        return false;
      }
      if (!this.messageTypeCode) {
        return false;
      }
      if (this.messageTypeCode === "OUTSOURCE_MESSAGE") {
        return true;
      }
      return false;
    },
    needsRecipient() {
      if (!this.selectedCase) {
        return false;
      }
      if (
        this.messageTypeCode === "DESIGN_MESSAGE" ||
        this.messageTypeCode === "PRODUCTION_MESSAGE" ||
        this.messageTypeCode === "LAB_MESSAGE"
      ) {
        return true;
      }
      return false;
    },
    needsApproval() {
      if (this.messageTypeCode === "APPROVAL_MESSAGE") {
        return true;
      }
      return false;
    },
    caseComponentsItems() {
      return this.collection.cases.map((kase) => {
        return {
          text: `${kase.job_type} (${kase.collection_reference})`,
          value: kase.uid,
        };
      });
    },
    approvalItems() {
      return this.collection.approvals
        .filter((approval) => {
          return !approval.approved_at && !approval.cancelled_at;
        })
        .map((approval) => {
          const kase = this.collection.cases.find(
            (kase) => kase.uid === approval.case_uid
          );
          return {
            text: `${this.toTitleCase(approval.approval_type)} - ${
              kase.job_type
            } (${kase.collection_reference})`,
            value: approval.uid,
          };
        });
    },
    outsourceJobItems() {
      if (!this.selectedCase) {
        return [];
      }
      return this.selectedCase.outsources
        .filter((outsource) => !outsource.end)
        .map((outsource) => {
          return {
            text: outsource.partner,
            value: outsource.uid,
          };
        });
    },
    employeeItems() {
      let employees = [...this.employees]
        .filter((employee) => {
          return employee.name.trim() !== "";
        })
        .sort((a, b) => a.name.localeCompare(b.name));
      if (this.messageTypeCode === "DESIGN_MESSAGE") {
        employees = this.employees.filter(
          (employee) => employee.department === "DESIGN"
        );
      } else if (this.messageTypeCode === "PRODUCTION_MESSAGE") {
        employees = this.employees.filter(
          (employee) => employee.department === "MACHINING"
        );
      } else if (this.messageTypeCode === "LAB_MESSAGE") {
        employees = this.employees.filter(
          (employee) => employee.department === "POLISHING"
        );
      }
      return employees.map((employee) => {
        return {
          text: employee.name,
          value: employee.username,
          branch: employee.main_branches[0],
          department: employee.department,
        };
      });
    },
    messageTypeItems() {
      return [...this.messageTypes]
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((type) => {
          return {
            text: type.name,
            value: type.code,
          };
        });
    },
    messageType() {
      if (!this.messageTypeCode) {
        return;
      }
      return this.messageTypes.find(
        (type) => type.code === this.messageTypeCode
      );
    },
    messagePayload() {
      const messageType = this.messageType ? this.messageType.code : null;
      const clientUID = this.collection.shipping_client.uid;
      const collectionUID =
        messageType !== "CLIENT_NOTE" ? this.collection.uid : null;
      let caseUID = null;
      if (
        messageType !== "CLIENT_NOTE" &&
        messageType !== "CLIENT_MESSAGE" &&
        messageType !== "CUSTOMER_SCAN_ADVICE"
      ) {
        if (this.kase) {
          caseUID = this.kase.uid;
        } else {
          caseUID = this.caseUID;
        }
      }
      const approvalUID =
        messageType === "APPROVAL_MESSAGE" ? this.approvalUID : null;
      const outsourceUID =
        messageType === "OUTSOURCE_MESSAGE" ? this.outsourceUID : null;
      let direction = "STATIC";
      let recipientReference = null;
      switch (messageType) {
        case "DESIGN_MESSAGE":
        case "PRODUCTION_MESSAGE":
        case "LAB_MESSAGE":
          direction = "OUTBOUND";
          recipientReference = this.employeeReference;
          break;
        case "CLIENT_MESSAGE":
        case "CUSTOMER_SCAN_ADVICE":
        case "APPROVAL_MESSAGE":
          if (this.approvalUID) {
            caseUID = this.collection.approvals.find(
              (approval) => approval.uid === this.approvalUID
            ).case_uid;
          }
          direction = "OUTBOUND";
          recipientReference = this.collection.shipping_client.name;
          break;
        case "OUTSOURCE_MESSAGE":
          direction = "OUTBOUND";
          if (
            this.selectedCase &&
            this.selectedCase.outsources.find(
              (os) => os.uid === this.outsourceUID
            )
          ) {
            recipientReference = this.selectedCase.outsources.find(
              (os) => os.uid === this.outsourceUID
            ).partner;
          }
          break;
      }
      return {
        client_uid: clientUID,
        case_collection_uid: collectionUID,
        case_uid: caseUID,
        approval_uid: approvalUID,
        outsource_uid: outsourceUID,
        message_type: messageType,
        author_reference: this.me.username,
        recipient_reference: recipientReference,
        direction: direction,
        note: this.message,
        resolvable:
          messageType === "ALERTING_MESSAGE" ||
          messageType === "CUSTOMER_SERVICE_NOTE",
      };
    },
    validation() {
      const errors = [];
      if (
        !this.messagePayload.note ||
        (this.messagePayload.note &&
          this.messagePayload.note.trim().length === 0)
      ) {
        errors.push("Please supply a message");
      }
      switch (this.messagePayload.message_type) {
        case "ALERTING_MESSAGE":
        case "CASE_NOTE":
        case "DESIGN_MESSAGE":
        case "DISPATCH_NOTE":
        case "LAB_MESSAGE":
        case "PAYMENT_NOTE":
        case "PRODUCTION_MESSAGE":
        case "SPECIAL_REQUEST":
          if (!this.messagePayload.case_uid) {
            errors.push("Please select a case");
          }
          break;
        case "APPROVAL_MESSAGE":
          if (!this.messagePayload.approval_uid) {
            errors.push("Please select an approval request");
          }
          break;
        case "OUTSOURCE_MESSAGE":
          if (!this.messagePayload.case_uid) {
            errors.push("Please select a case");
          }
          if (!this.messagePayload.outsource_uid) {
            errors.push("Please select an outsource job");
          }
          break;
        case "CLIENT_MESSAGE":
        case "CUSTOMER_SCAN_ADVICE":
        case "CLIENT_NOTE":
        case "SALES_MESSAGE":
        case "CUSTOMER_SERVICE_NOTE":
          break;
        default:
          errors.push("Invalid message type");
      }
      return errors.length > 0 ? errors : true;
    },
  },
  methods: {
    ...mapActions(["startLoading", "stopLoading", "triggerMessageReload"]),
    matchFilters(filters) {
      if (filters.approvalFilter && filters.approvalFilter.length > 0) {
        this.messageTypeCode = "APPROVAL_MESSAGE";
        const approval = this.collection.approvals.find((approval) => {
          const kase = this.collection.cases.find(
            (kase) => kase.uid === approval.case_uid
          );
          if (!kase) {
            return false;
          }
          return (
            `${this.toTitleCase(approval.approval_type)} - ${kase.job_type}` ===
            filters.approvalFilter[0]
          );
        });
        if (approval) {
          this.approvalUID = approval.uid;
        }
        return;
      }
      if (filters.messageTypeFilter && filters.messageTypeFilter.length > 0) {
        const messageType = this.messageTypes.find(
          (type) => type.name === filters.messageTypeFilter[0]
        );
        if (messageType) {
          this.messageTypeCode = messageType.code;
        }
        return;
      }
      this.messageTypeCode = null;
      this.approvalUID = null;
    },
    removeAttachment(attachment) {
      const index = this.attachments.indexOf(attachment);
      if (index < 0) {
        return;
      }
      this.attachments.splice(index, 1);
    },
    triggerSend() {
      if (!(this.validation === true)) {
        return;
      }
      this.confirmToSend = true;
    },
    async send() {
      if (!(this.validation === true)) {
        return;
      }
      if (this.messageTypeCode === "CLIENT_NOTE") {
        if (
          !confirm(
            "You're about to attach this message to a client's record - this will be visible on all cases. Are you sure?"
          )
        ) {
          return;
        }
      }
      this.loading = true;
      this.startLoading();
      const formData = new FormData();
      for (var key in this.messagePayload) {
        if (this.messagePayload[key]) {
          formData.append(key, this.messagePayload[key]);
        }
      }
      const uploads = [];
      this.attachments.forEach((attachment) => {
        uploads.push(this.handleFileUpload(attachment));
      });
      try {
        const uploadResults = await Promise.all(uploads);
        uploadResults.forEach((result) =>
          formData.append("oft_attachments", result.file_name)
        );
      } catch (error) {
        console.error(error);
        this.stopLoading();
        this.loading = false;
        this.errorMessage = "Error uploading attachments";
        return;
      }

      this.$axios
        .post("/api/v2/messages/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.triggerMessageReload();
          this.stopLoading();
          this.reset();
        })
        .catch(() => {
          this.errorMessage = "Error submitting message";
        });
    },
    async handleFileUpload(file) {
      const oftResponse = await this.oftAddFile(file);
      await this.uploadToGoogleStorage(
        file,
        oftResponse.data[0].oneTimeUploadLink
      );

      return {
        file_name: oftResponse.data[0].fileName,
        oft_file_uid: oftResponse.data[0].uid,
      };
    },
    async oftAddFile(file) {
      try {
        const country = this.collection.shipping_client.country;
        const response = await this.$axios.post(
          `${options.oftURL}/upload/${country}`,
          [
            {
              fileName: file.name,
              fileReferences: [
                {
                  entityType: "COLLECTION",
                  entityIdentification: this.collection.uid,
                },
              ],
            },
          ]
        );
        return response;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async uploadToGoogleStorage(file, uploadLink) {
      try {
        const response = await fetch(uploadLink, {
          method: "PUT",
          body: file,
        });
        return response;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    reset() {
      this.caseUID = null;
      this.outsourceUID = null;
      this.employeeReference = null;
      this.messageTypeCode = null;
      this.message = null;
      this.attachments = [];
      this.confirmToSend = false;
      this.$emit("sent", true);
      this.loading = false;
      this.errorMessage = null;
    },
    toTitleCase(string) {
      return string
        .replaceAll("_", " ")
        .replace(
          /[a-zA-Z]+/g,
          (text) => text.charAt(0).toUpperCase() + text.substr(1).toLowerCase()
        );
    },
  },
  mounted() {
    this.matchFilters(this.messageFilter);
    this.messageTypeCode = fetchURLParam("messageTypeCode");
  },
};
</script>

<style>
.chatbox-buttons {
  display: inline !important;
  margin-top: 0px !important;
  margin-right: 8px !important;
  padding-top: 0px !important;
}

.chatbox-buttons > .v-input__prepend-outer {
  display: inline !important;
  margin-top: 2px !important;
  margin-right: 0px !important;
  padding-top: 0px !important;
}
</style>
