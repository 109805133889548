<template>
  <v-card outlined rounded="lg">
    <v-card-title>
      <v-row class="mb-2">
        <v-col sm="12" lg="9">
          <v-icon class="mr-2">mdi-information</v-icon>
          Basic Information
        </v-col>
        <v-col sm="12" lg="3" class="text-right">
          <v-btn v-if="!edit" small @click="edit = true" class="rounded-lg">
            <v-icon small>mdi-lock</v-icon>
          </v-btn>
          <v-btn v-if="edit" small @click="edit = false" class="rounded-lg">
            <v-icon small>mdi-lock-open</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text v-if="loading" class="text-center">
      <v-progress-circular indeterminate size="128" color="primary">
        Loading
      </v-progress-circular>
    </v-card-text>
    <v-card-text v-if="edit && !loading">
      <v-form lazy-validation ref="collectionUpdateForm">
        <v-text-field
          outlined
          dense
          readonly
          v-model="collection.shipping_client.name"
          v-if="collection.shipping_client"
          label="Shipping Client"
          class="rounded-lg"
          append-outer-icon="mdi-swap-horizontal"
          @click:append-outer="collection.shipping_client = null"
        >
        </v-text-field>
        <ClientSearch
          v-model="collection.shipping_client"
          v-if="!collection.shipping_client"
          :errors="shippingClientValidator"
          type="Shipping"
        />
        <v-text-field
          outlined
          dense
          readonly
          v-model="collection.billing_client.name"
          v-if="collection.billing_client"
          label="Billing Client"
          class="rounded-lg"
          append-outer-icon="mdi-swap-horizontal"
          @click:append-outer="collection.billing_client = null"
        >
        </v-text-field>
        <ClientSearch
          v-model="collection.billing_client"
          v-if="!collection.billing_client"
          type="Billing"
        />
        <v-text-field
          outlined
          dense
          label="Patient Reference"
          class="rounded-lg"
          :rules="[patientReferenceValidator]"
          v-model="collection.patient_reference"
        />
        <v-autocomplete
          dense
          outlined
          class="rounded-lg"
          v-model="collection.ordering_platform"
          :items="platformTagItems"
          :rules="[orderingPlatformValidator]"
          label="Platform Tag"
        />
        <v-autocomplete
          dense
          outlined
          class="rounded-lg"
          v-model="collection.fulfillment_branch_id"
          :items="branchItems"
          :rules="[fulfillmentBranchValidator]"
          @input="openBranchConfirmationDialog"
          label="Fulfillment Branch"
        />
        <ConfirmDialog
          v-model="dialog"
          @confirmation="setConfirmation"
          :title="title"
          :message="message"
        />
        <v-row>
          <v-col>
            <PricingKitCard
              :kitItems="billKitItems"
              :pricingKits="collection.pricing_kits"
              :edit="edit"
              @addPricingKit="addPricingKit"
              @deletePricingKit="deletePricingKit"
            />
          </v-col>
        </v-row>
      </v-form>
      <v-row>
        <v-col class="text-right">
          <v-btn
            class="success rounded-lg"
            @click="updateCollection()"
            :disabled="submitting"
          >
            <v-icon class="mr-2">mdi-content-save</v-icon>
            Save
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text v-if="!edit">
      <v-row>
        <v-col sm="12" lg="4" class="pb-0">
          <strong>Time Created</strong>
        </v-col>
        <v-col sm="12" lg="8" class="pb-0">
          <v-icon small>mdi-clock</v-icon>
          {{ collection.added | luxon({ output: "tz_med" }) }}
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12" lg="4" class="pb-0">
          <strong>Status</strong>
        </v-col>
        <v-col sm="12" lg="8" class="pb-0">
          <v-chip small color="success" v-if="collection.active">Active</v-chip>
          <v-chip small v-if="!collection.active">Archived</v-chip>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12" lg="4" class="pb-0">
          <strong>Shipping Client</strong>
        </v-col>
        <v-col sm="12" lg="8" class="pb-0" v-if="collection.shipping_client">
          <a @click="showClient(collection.shipping_client.id)">{{
            collection.shipping_client.name
          }}</a>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12" lg="4" class="pb-0">
          <strong>Billing Client</strong>
        </v-col>
        <v-col sm="12" lg="8" class="pb-0" v-if="collection.billing_client">
          <a @click="showClient(collection.billing_client.id)">{{
            collection.billing_client.name
          }}</a>
        </v-col>
      </v-row>
      <v-row v-if="collection.referrals && collection.referrals.length > 0">
        <v-col sm="12" class="pb-0 text-right">
          <v-btn small class="rounded-lg" @click="referralModal = true">
            Referral History
            <v-avatar size="16" color="primary" class="ml-3">
              <span class="white--text">
                {{ collection.referrals.length }}
              </span>
            </v-avatar>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12" lg="4" class="pb-0">
          <strong>Patient Reference</strong>
        </v-col>
        <v-col sm="12" lg="8" class="pb-0">
          {{ collection.patient_reference }}
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12" lg="4" class="pb-0">
          <strong>Ordering Platform</strong>
        </v-col>
        <v-col sm="12" lg="8" class="pb-0">
          <v-chip
            v-if="collection.ordering_platform === 'nexus_ios'"
            x-small
            outlined
          >
            Nexus IOS
          </v-chip>
          <v-chip
            v-if="collection.ordering_platform === 'oep'"
            x-small
            outlined
          >
            Osteon
          </v-chip>
          <v-chip
            v-if="collection.ordering_platform === 'nclab'"
            x-small
            outlined
          >
            Nexus Connect for Labs
          </v-chip>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12" lg="4" class="pb-0">
          <strong>Fulfillment Branch</strong>
        </v-col>
        <v-col sm="12" lg="8" class="pb-0">
          {{ collection.fulfillment_branch }}
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12" lg="4" class="pb-0">
          <strong>Case Box Location</strong>
        </v-col>
        <v-col sm="12" lg="8" class="pb-0">
          <BinLocation
            :bin-location="collection.bin_location"
            :bin-location-branch="collection.bin_location_branch"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12" lg="4" class="pb-0">
          <strong>Case Box Required</strong>
        </v-col>
        <v-col sm="12" lg="8" class="pb-0">
          <v-checkbox
            class="mt-0 pt-0"
            v-model="collection.location_required"
            @change="toggleLocationRequired()"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12" lg="4" class="pb-0">
          <strong>Pricing Kit</strong>
        </v-col>
        <v-col sm="12" lg="8" class="pb-0">
          <ul v-for="(pricing, index) in collection.pricing_kits" :key="index">
            <li>{{ pricing.kit_dentate_name }} - {{ pricing.kit_name }}</li>
          </ul>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12" class="text-right">
          <v-btn
            dark
            color="blue darken-4"
            class="mr-2 rounded-lg"
            @click="toggleVIP()"
          >
            <v-icon class="mr-2">mdi-decagram</v-icon>
            Toggle VIP
          </v-btn>
        </v-col>
      </v-row>
      <v-dialog v-model="referralModal" max-width="1200">
        <v-card class="rounded-lg">
          <v-toolbar dark>
            <v-btn icon dark @click="referralModal = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>
              Referral History
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-simple-table dense>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Referrer Name</th>
                  <th>Referree ID</th>
                  <th>Referree Name</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(referral, index) in collection.referrals"
                  :key="index"
                >
                  <td>
                    {{
                      referral.created_at | luxon({ output: { format: "med" } })
                    }}
                  </td>
                  <td>
                    {{ referral.referrer_client_name }}
                  </td>
                  <td>
                    {{ referral.referree_identification }}
                  </td>
                  <td>
                    {{ referral.referree_client_name }}
                  </td>
                  <td>
                    <span v-if="referral.accepted_at">
                      <v-icon color="success" class="mr-2">mdi-check</v-icon>
                      Accepted
                    </span>
                    <span v-if="referral.canceled_at">
                      <v-icon color="error" class="mr-2">mdi-cancel</v-icon>
                      Canceled
                    </span>
                    <span v-if="!referral.accepted_at && !referral.canceled_at">
                      <v-icon color="primary" class="mr-2">mdi-clock</v-icon>
                      Pending
                    </span>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ClientSearch from "@/collection/components/shared/ClientSearch";
import PricingKitCard from "@/shared/PricingKitCard";
import ConfirmDialog from "@/shared/ConfirmDialog";
import BinLocation from "@/shared/BinLocation";

export default {
  name: "CollectionInfo",
  components: {
    ClientSearch,
    PricingKitCard,
    ConfirmDialog,
    BinLocation,
  },
  data() {
    return {
      edit: false,
      submitting: false,
      loading: false,
      dialog: false,
      title: "",
      message: "",
      originalFulfillmentBranchID: null,
      confirmation: null,
      referralModal: false,
    };
  },
  watch: {
    edit(value) {
      if (value) {
        this.originalFulfillmentBranchID = this.collection.fulfillment_branch_id;
        this.startEditing();
      } else {
        this.originalFulfillmentBranchID = null;
        this.stopEditing();
        this.triggerReload();
      }
    },
  },
  computed: {
    ...mapGetters(["collection", "branches", "billKits"]),
    platformTagItems() {
      return [
        { text: "Osteon", value: "oep" },
        { text: "Nexus iOS", value: "nexus_ios" },
        { text: "Nexus Connect for Labs", value: "nclab" },
      ];
    },
    branchItems() {
      return this.branches.map((branch) => {
        return {
          text: branch.name,
          value: branch.id,
        };
      });
    },
    patientReferenceValidator() {
      if (!this.collection.patient_reference) {
        return "Cannot be blank";
      }
      return true;
    },
    fulfillmentBranchValidator() {
      if (!this.collection.fulfillment_branch_id) {
        return "Cannot be blank";
      }
      return true;
    },
    orderingPlatformValidator() {
      if (!this.collection.ordering_platform) {
        return "Cannot be blank";
      }
      return true;
    },
    shippingClientValidator() {
      if (!this.collection.shipping_client) {
        return "Cannot be blank";
      }
      return true;
    },
    billKitItems() {
      return this.billKits.map((kit) => {
        return {
          text: kit.name,
          value: kit.id,
        };
      });
    },
  },
  methods: {
    ...mapActions([
      "startLoading",
      "stopLoading",
      "triggerReload",
      "startEditing",
      "stopEditing",
    ]),
    async toggleLocationRequired() {
      const message = this.collection.location_required
        ? "Are you sure you want to allocate a case location?"
        : "Are you sure you want to unallocate the case location?";
      if (!confirm(message)) {
        return;
      }
      this.startLoading();
      this.$axios
        .post(`/api/v2/collection/${this.collection.uid}/`, {
          action: "toggle_location",
        })
        .then(() => {
          this.triggerReload();
          this.stopLoading();
        });
    },
    async updateCollection() {
      if (
        !this.$refs.collectionUpdateForm.validate() ||
        !this.collection.shipping_client
      ) {
        return;
      }
      this.startLoading();
      this.submitting = true;
      this.$axios
        .patch(`/api/v2/collection/${this.collection.uid}/`, {
          patient_reference: this.collection.patient_reference,
          ordering_platform: this.collection.ordering_platform,
          fulfillment_branch_id: this.collection.fulfillment_branch_id,
          shipping_client_uid: this.collection.shipping_client
            ? this.collection.shipping_client.uid
            : null,
          billing_client_uid: this.collection.billing_client
            ? this.collection.billing_client.uid
            : null,
          pricing_kits: this.collection.pricing_kits
            ? this.collection.pricing_kits
            : null,
        })
        .then(() => {
          this.triggerReload();
          this.stopLoading();
          this.edit = false;
          this.submitting = false;
        });
    },
    async toggleVIP() {
      this.startLoading();
      this.$axios
        .post(`/api/v2/collection/${this.collection.uid}/`, {
          action: "toggle_vip",
        })
        .then(() => {
          this.triggerReload();
          this.stopLoading();
        });
    },
    showClient(clientID) {
      window.open(`/static/v2/client.html?id=${clientID}`, "_blank");
    },
    addPricingKit(pricingKit) {
      this.collection.pricing_kits.push(pricingKit);
    },
    deletePricingKit(pricingKit) {
      this.collection.pricing_kits.splice(
        this.collection.pricing_kits.findIndex(
          (pricing) =>
            pricing.kit_collection_id === pricingKit.kit_collection_id
        ),
        1
      );
    },
    openBranchConfirmationDialog() {
      var originalBranch = this.branches.find(
        (branch) => branch.id === this.originalFulfillmentBranchID
      );
      if (!originalBranch) {
        return;
      }
      this.dialog = true;
      this.title = "WARNING";
      this.message = `
Changing the fulfillment branch will trigger case files to be transfered to a different server.\n
This will impact production scheduling, inventory, and invoicing.\n
In addition, the implants, screws and other components associated to this case may not be available in the branch you're selecting.\n
ENSURE YOU UNDERSTAND THE CONSEQUENCES BEFORE PROCEEDING!\n
The current branch is ${originalBranch.name}, are you sure you want to change this?
      `;
    },
    setConfirmation(value) {
      this.confirmation = value;
      if (!this.confirmation) {
        this.collection.fulfillment_branch_id = this.originalFulfillmentBranchID;
      }
    },
  },
};
</script>
